var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manuscriptIncomeCompenent" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("稿件收益")]),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c("el-input", {
            staticStyle: { width: "260px" },
            attrs: {
              size: "small",
              placeholder: "请输入作者姓名、单位、手机号检索",
            },
            on: { input: _vm.inputValue },
            model: {
              value: _vm.searchValue,
              callback: function ($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue",
            },
          }),
          _c("div", { staticClass: "operateRight" }, [
            _c(
              "div",
              {
                staticClass: "operateBtn",
                on: {
                  click: function ($event) {
                    _vm.setupManuscriptFlag = true
                  },
                },
              },
              [_vm._v("稿酬计算设置")]
            ),
            _c(
              "div",
              {
                staticClass: "operateBtn",
                staticStyle: { border: "1px solid #634AC6", color: "#634AC6" },
                on: { click: _vm.confirmBatchFee },
              },
              [_vm._v("批量确认支付")]
            ),
            _c(
              "div",
              {
                staticClass: "operateBtn",
                on: {
                  click: function ($event) {
                    _vm.uploadExcelFlag = true
                  },
                },
              },
              [_vm._v("导出Excel")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableList", staticClass: "tableList" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                fit: "",
                stripe: "",
                height: _vm.tableHeight,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "用户名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "author", label: "作者" },
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  "show-overflow-tooltip": "",
                  label: "标题",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "公众号" },
              }),
              _c("el-table-column", {
                attrs: { prop: "publishDate", label: "发表日期" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fdDeliveryConversionRate",
                  label: "首日送达转化率(%)",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "totalFee", label: "总稿酬" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              "visible-arrow": false,
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", { staticStyle: { color: "#fff" } }, [
                              _c("div", [
                                _vm._v("基础稿酬 " + _vm._s(scope.row.baseFee)),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "原创奖励 " + _vm._s(scope.row.originalFee)
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: { color: "#634AC6" },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(scope.row.totalFee))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "settlementStatus",
                  width: "100",
                  label: "状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.settlementStatus == 0
                                ? "待支付"
                                : "已支付"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "penName",
                  align: "center",
                  width: "100",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "operateItem",
                              style: {
                                color:
                                  scope.row.settlementStatus == 0
                                    ? "#23AB62"
                                    : "#F36C32",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFee(scope.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  scope.row.settlementStatus == 0
                                    ? "确认支付"
                                    : "撤销支付"
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.total,
              limit: _vm.pageSize,
              currentPage: _vm.currentPage,
            },
            on: { pagination: _vm.pagination },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "tip" }, [
        _vm._v(
          "*如果您对稿酬发放存在疑问或遇到困难，请添加客服微信touhaoyaoshi进行咨询。"
        ),
      ]),
      _vm.uploadExcelFlag || _vm.setupManuscriptFlag
        ? _c("userOverlayout")
        : _vm._e(),
      _c("setupManuscript", {
        attrs: { setupManuscriptFlag: _vm.setupManuscriptFlag },
        on: {
          setupManuscriptConfirm: _vm.setupManuscriptConfirm,
          closeSetupManuscriptConfirm: function ($event) {
            _vm.setupManuscriptFlag = false
          },
        },
      }),
      _c("uploadExcel", {
        attrs: { uploadExcelFlag: _vm.uploadExcelFlag },
        on: {
          closeUploadExcel: function ($event) {
            _vm.uploadExcelFlag = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }