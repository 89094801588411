<template>
	<div class="manuscriptIncomeCompenent">
		<div class="title">稿件收益</div>
		<div class="operate">
			<el-input style="width: 260px;" size="small" @input="inputValue" v-model="searchValue" placeholder="请输入作者姓名、单位、手机号检索"></el-input>
			<div class="operateRight">
				<div @click="setupManuscriptFlag=true" class="operateBtn">稿酬计算设置</div>
				<div @click="confirmBatchFee" style="border: 1px solid #634AC6;color: #634AC6" class="operateBtn">批量确认支付</div>
				<div @click="uploadExcelFlag=true" class="operateBtn">导出Excel</div>
			</div>
		</div>
		<div class="tableList" ref="tableList">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
				fit
        stripe
        :height="tableHeight"
				@selection-change="handleSelectionChange"
        style="width: 100%">
				<el-table-column
					type="selection"
					width="55">
				</el-table-column>
        <el-table-column
          prop="userName"
          label="用户名">
        </el-table-column>
				<el-table-column
          prop="author"
          label="作者">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号">
        </el-table-column>
				<el-table-column
          prop="title"
					show-overflow-tooltip
          label="标题">
        </el-table-column>
				<el-table-column
          prop="appName"
          label="公众号">
        </el-table-column>
        <el-table-column
          prop="publishDate"
          label="发表日期">
        </el-table-column>
        <el-table-column
          prop="fdDeliveryConversionRate"
          label="首日送达转化率(%)">
        </el-table-column>
				<el-table-column
          prop="totalFee"
          label="总稿酬">
					<template slot-scope="scope">
						<el-popover
							placement="top-start"
							:visible-arrow="false"
							trigger="hover">
							<div style="color: #fff">
								<div>基础稿酬&nbsp{{scope.row.baseFee}}</div>
								<div>原创奖励&nbsp{{scope.row.originalFee}}</div>
							</div>
							<div slot="reference" style="color: #634AC6">{{scope.row.totalFee}}</div>
						</el-popover>
            <!-- <el-tooltip class="item" :visible-arrow="false" :close-delay="100000" effect="dark" content="Right Center 提示文字" placement="right">
							<div style="color: #634AC6">{{scope.row.money}}</div>
						</el-tooltip> -->
          </template>
        </el-table-column>
				<el-table-column
					prop="settlementStatus"
					width="100"
					label="状态">
					<template slot-scope="scope">
						<div>{{scope.row.settlementStatus==0 ? '待支付' : '已支付'}}</div>
					</template>
				</el-table-column>
				<el-table-column
          prop="penName"
					align="center"
					width="100"
          label="操作">
					<template slot-scope="scope">
						<div>
							<span @click="handleFee(scope.row)" :style="{color: scope.row.settlementStatus==0 ? '#23AB62' : '#F36C32'}" class="operateItem">{{scope.row.settlementStatus==0 ? '确认支付' : '撤销支付'}}</span>
						</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
		<div class="pagination">
      <Pagination :total="total" :limit="pageSize" :currentPage="currentPage" @pagination="pagination"></Pagination>
    </div>
		<div class="tip">*如果您对稿酬发放存在疑问或遇到困难，请添加客服微信touhaoyaoshi进行咨询。</div>
		<userOverlayout v-if="uploadExcelFlag || setupManuscriptFlag"></userOverlayout>
		<setupManuscript :setupManuscriptFlag="setupManuscriptFlag" @setupManuscriptConfirm="setupManuscriptConfirm" @closeSetupManuscriptConfirm="setupManuscriptFlag=false"></setupManuscript>
		<uploadExcel :uploadExcelFlag="uploadExcelFlag" @closeUploadExcel="uploadExcelFlag=false"></uploadExcel>
	</div>
</template>
<script>
import {feeList} from '../../../api/home.js'
import {batchAckFee, rollbackFee} from '../../../api/userHome.js'
import userOverlayout from '../../commonComponent/userOverlayout.vue'
import uploadExcel from '../../commonComponent/uploadExcel.vue'
import setupManuscript from '../../commonComponent/setupManuscript.vue'
export default {
  name: 'manuscriptIncomeCompenent',
	components: {
		uploadExcel,
		userOverlayout,
		setupManuscript
	},
  data() {
    return {
			searchValue: '',
			tableData: [],
			tableHeight: 0,
			total: 0,
			pageSize: 10,
			currentPage: 1,
			loading: false,
			ids: [],
			uploadExcelFlag: false,
			setupManuscriptFlag: false
    }
  },
  created() {
  },
	mounted() {
		this.getTable()
		window.addEventListener('resize', () => {
			this.tableHeight = 0
			this.$nextTick(() => {
        this.tableHeight = this.$refs.tableList.clientHeight
      })
    })
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableList.clientHeight
    })
	},
  methods: {
		async getTable() {
			this.loading = true
			const params = {
				pageNum: this.currentPage,
				pageSize: this.pageSize,
				month: '',
				searchValue: this.searchValue,
			}
			let res = await feeList(params)
			this.loading = false
			if(res.code == 200) {
				this.tableData = res.rows
				this.total = res.total
			}
		},
		setupManuscriptConfirm() {
			this.setupManuscriptFlag = false
			this.getTable()
		},
		async handleRollbackFee(id) {
			let res = await rollbackFee(id)
			if(res.code == 200) {
				this.getTable()
			}
		},
		async handleBatchAckFee() {
			const params = this.ids
			let res = await batchAckFee(params)
			if(res.code == 200) {
				this.ids = []
				this.getTable()
			}
		},
		confirmBatchFee() {
			if(this.ids.length == 0) {
				this.$message.error('至少选择一条数据')
				return
			}
			this.handleBatchAckFee()
		},
		handleFee(row) {
			if(row.settlementStatus == '0') {
				this.ids = [row.id]
				this.handleBatchAckFee()
			} else {
				this.handleRollbackFee(row.id)
			}
		},
		handleSelectionChange(val) {
			this.ids = []
			val.forEach(item => {
				this.ids.push(item.id)
			})
		},
		inputValue() {
			this.currentPage = 1
			this.getTable()
		},
		pagination(val) {
      this.pageSize = val.limit
			this.currentPage = val.page
      this.getTable()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-table__cell {
	padding: 8px 12px !important;
}
.manuscriptIncomeCompenent {
	padding: 36px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100vh;
	.operate {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
		.operateRight {
			display: flex;
			align-items: center;
			.operateBtn +.operateBtn {
				margin-left: 20px;
			}
			.operateBtn {
				display: flex;
				padding: 8px 20px;
				box-sizing: border-box;
				justify-content: center;
				align-items: center;
				border-radius: 4px;
				border: 1px solid #B9B9B9;
				background: #FFF;
				line-height: 14px;
				font-size: 14px;
				color: #747474;
				cursor: pointer;
			}
		}
	}
	.pagination {
    //margin-top: 20px;
    border-top: unset;
    flex: 0 0 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBEEF5;
    border-radius: 0px 0px 8px 8px;
  }
	.tip {
		color: #808388;
		font-size: 12px;
		line-height: 23px;
		margin-top: 20px;
	}
	.tableList {
    flex: 1;
    overflow-y: hidden;
		.operateItem {
			line-height: 23px;
			font-size: 14px;
			cursor: pointer;
		}
	}
	.title {
		color: #303133;
		font-size: 20px;
		font-weight: bold;
		line-height: 20px;
		margin-bottom: 32px;
	}
}
</style>