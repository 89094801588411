var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.setupManuscriptFlag
    ? _c("div", { staticClass: "setupManuscript" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [_vm._v("稿酬计算设置")]),
          _c("img", {
            staticStyle: { width: "15px", height: "15px", cursor: "pointer" },
            attrs: { src: require("../../assets/images/close.png") },
            on: { click: _vm.close },
          }),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "content",
          },
          _vm._l(_vm.setupData, function (item, index) {
            return _c("div", { key: index, staticClass: "configItem" }, [
              _c("div", { staticClass: "configItemName" }, [
                _vm._v(_vm._s(item.appName)),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c("div", { staticClass: "configItemLabel" }, [
                    _vm._v("基础稿酬倍率："),
                  ]),
                  _c("el-input", {
                    attrs: { onkeyup: "value=value.replace(/[^\\d]/g,'')" },
                    model: {
                      value: item.baseMultiple,
                      callback: function ($$v) {
                        _vm.$set(item, "baseMultiple", $$v)
                      },
                      expression: "item.baseMultiple",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c("div", { staticClass: "configItemLabel" }, [
                    _vm._v("原创奖励："),
                  ]),
                  _c("el-input", {
                    attrs: { onkeyup: "value=value.replace(/[^\\d]/g,'')" },
                    model: {
                      value: item.originalFee,
                      callback: function ($$v) {
                        _vm.$set(item, "originalFee", $$v)
                      },
                      expression: "item.originalFee",
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "comfirn", on: { click: _vm.comfirn } }, [
            _vm._v("确定"),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }